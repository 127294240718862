import React, { useEffect, useRef, useState } from "react";
import interact from "interactjs";
import pexels from "../../pexels/pexels";
import styled from "styled-components";
import ReactPlayer from "react-player";
import affirmations from "../../constants/affirmations";

const TextContainer = styled.div`
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 8px;
  color: white;
  display: flex;
  font-family: sans-serif;
  justify-content: center;
  left: 8%;
  margin: 1rem;
  padding: 20px;
  position: fixed;
  text-align: left;
  touch-action: none;
  top: 30vh;
  width: 50%;
  z-index: 3;
  /* This makes things *much* easier */
  box-sizing: border-box;

  &:hover {
    background-color: rgba(191, 212, 253, 0.1);
  }

  @media only screen and (max-width: 600px) {
    top: 10vh;
    width: 60%;
  }
`;

const CreditLink = styled.a`
  bottom: 0;
  color: #696969;
  cursor: pointer;
  position: fixed;
  left: 0;
  margin-left: 7px;
  text-decoration: none;
`;

const AffirmationInputText = styled.textarea`
  background-color: rgb(255, 255, 255, 0.6);
  border: none;
  display: block;
  font-family: sans-serif;
  font-size: 30px;
  margin-left: -9px;
  margin-top: 10px;
  padding: 10px;
  resize: none;
  width: 96%;
`;

const CursorWrapper = styled.div`
  width: 100%;
`;

function HomeView(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [items, setItems] = useState([]);
  const [artistData, setArtistData] = useState({});
  const [videoLink, setVideoLink] = useState("");
  const [searchQuery, setSearchQuery] = useState("nature");
  const [affirmation, setAffirmation] = useState("");
  const [customAffirmationText, setCustomAffirmationText] = useState("");
  const [showInputText, setShowInputText] = useState(false);

  const numberOfResults = 20;
  const pageNumber = Math.floor(Math.random() * 39);
  const randomVideoNumber = Math.floor(Math.random() * numberOfResults);
  const randomAffirmationNumber = Math.floor(
    Math.random() * affirmations.length
  );

  useEffect(() => {
    setIsLoaded(false);
    async function fetchData() {
      const response = await pexels.get(`/videos/search`, {
        params: {
          query: searchQuery,
          per_page: numberOfResults,
          page: pageNumber,
        },
      });
      setItems({ videos: response.data.videos });
      setVideoLink(response.data.videos[randomVideoNumber].video_files[1].link);
      setArtistData(response.data.videos[randomVideoNumber].user);
      setAffirmation(affirmations[randomAffirmationNumber - 1]);
      setIsLoaded(true);
    }
    fetchData();
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowInputText(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const changeBackground = () => {
    const randomNumber = Math.floor(Math.random() * (numberOfResults - 1));
    setVideoLink(items.videos[randomNumber].video_files[1].link);
  };

  const changeAffirmation = () => {
    setIsLoaded(false);
    const randomNumber = Math.floor(Math.random() * (affirmations.length - 1));
    setCustomAffirmationText("");
    setAffirmation(affirmations[randomNumber]);
    setIsLoaded(true);
  };

  const handleChange = (event) => {
    setCustomAffirmationText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setShowInputText(false);
    }
  };

  interact(".resize-drag")
    .resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },

      listeners: {
        move(event) {
          var target = event.target;
          var x = parseFloat(target.getAttribute("data-x")) || 0;
          var y = parseFloat(target.getAttribute("data-y")) || 0;

          // update the element's style
          target.style.width = event.rect.width + "px";
          target.style.height = event.rect.height + "px";

          // translate when resizing from top or left edges
          x += event.deltaRect.left;
          y += event.deltaRect.top;

          target.style.webkitTransform = target.style.transform =
            "translate(" + x + "px," + y + "px)";

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },
      },
      modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 100, height: 50 },
        }),
      ],

      inertia: true,
    })
    .draggable({
      // enable inertial throwing
      inertia: true,
      // keep the element within the area of it's parent
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
          endOnly: true,
        }),
      ],
      // enable autoScroll
      autoScroll: true,

      listeners: {
        // call this function on every dragmove event
        move: dragMoveListener,
      },
    });

  function dragMoveListener(event) {
    var target = event.target;
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.webkitTransform = target.style.transform =
      "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <ReactPlayer playsinline loop muted volume={1} playing url={videoLink} width="100%" height="100%" />
        <TextContainer className="resize-drag">
          <h1>
            {affirmation.text}
            {affirmation.fillInBlank &&
              !showInputText &&
              !customAffirmationText && (
                <CursorWrapper onClick={() => setShowInputText(true)}>
                  <div id="cursor"></div>
                </CursorWrapper>
              )}
            {affirmation.fillInBlank && !showInputText && (
              <span onClick={() => setShowInputText(true)}>
                {customAffirmationText}
              </span>
            )}
            {showInputText && (
              <AffirmationInputText
                ref={wrapperRef}
                type="text"
                onChange={(e) => handleChange(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                value={customAffirmationText}
              />
            )}
          </h1>
        </TextContainer>
        
        <div className="container" onClick={() => changeBackground()}>
          <p className="animated-word-offset">new visual</p>
        </div>
        <div className="container" onClick={() => changeAffirmation()}>
          <p className="animated-word affirmation">new affirmation</p>
        </div>
        <div className="credits">
          <CreditLink href={artistData.url} target="_blank">
            <span>
              <a href="https://www.pexels.com" target="_blank">
                <img src="https://images.pexels.com/lib/api/pexels-white.png" />
              </a>
              video artist {artistData.name}
            </span>
          </CreditLink>
        </div>
      </div>
    );
  }
}

export default HomeView;
