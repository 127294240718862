import React from "react";
import "./App.css";
import HomeView from "./components/HomeView/HomeView";

function App() {
  return (
    <div className="App" style={{ height: "100vh", width: "100vw" }}>
      <HomeView />
    </div>
  );
}

export default App;
