export default [
  {
    text: "I create a safe and secure space for myself wherever I am.",
    fillInBlank: false,
  },
  {
    text: "I give myself permission to do what is right for me.",
    fillInBlank: false,
  },
  {
    text: "I am confident in my ability to ",
    fillInBlank: true,
  },
  {
    text: "I use my time and talents to help others ",
    fillInBlank: true,
  },
  {
    text: "What I love about myself is my ability to ",
    fillInBlank: true,
  },
  {
    text: "I feel proud of myself when I ",
    fillInBlank: true,
  },
  {
    text: "I give myself space to grow and learn.",
    fillInBlank: false,
  },
  {
    text: "I allow myself to be who I am without judgment.",
    fillInBlank: false,
  },
  {
    text: "I listen to my intuition and trust my inner guide.",
    fillInBlank: false,
  },
  {
    text: "I accept my emotions and let them serve their purpose.",
    fillInBlank: false,
  },
  {
    text: "I give myself the care and attention that I deserve.",
    fillInBlank: false,
  },
  {
    text: "My drive and ambition allow me to achieve my goals.",
    fillInBlank: false,
  },
  {
    text: "I share my talents with the world by ",
    fillInBlank: true,
  },
  {
    text: "I am good at helping others to ",
    fillInBlank: true,
  },
  {
    text: "I am always headed in the right direction.",
    fillInBlank: false,
  },
  {
    text: "I trust that I am on the right path.",
    fillInBlank: false,
  },
  {
    text: "I am creatively inspired by the world around me.",
    fillInBlank: false,
  },
  {
    text: "My mind is full of brilliant ideas.",
    fillInBlank: false,
  },
  {
    text: "I put my energy into things that matter to me.",
    fillInBlank: false,
  },
  {
    text: "I trust myself to make the right decision.",
    fillInBlank: false,
  },
  {
    text: "I am becoming closer to my true self every day.",
    fillInBlank: false,
  },
  {
    text: "I am grateful to have people in my life who ",
    fillInBlank: true,
  },
  {
    text: "I am learning valuable lessons from myself every day.",
    fillInBlank: false,
  },
  {
    text: "I am at peace with who I am as a person.",
    fillInBlank: false,
  },
  {
    text: "I make a difference in the world by simply existing in it.",
    fillInBlank: false,
  },
  {
    text: "I deserve whatever good comes my way today.",
    fillInBlank: false,
  },
  {
    text: "Today, and every day, I choose to be ",
    fillInBlank: true,
  },
  {
    text: "I deserve whatever good comes my way today.",
    fillInBlank: false,
  },
  {
    text: "I plan my work and work my plan.",
    fillInBlank: false,
  },
  {
    text: "Happiness is a choice. I choose to be happy.",
    fillInBlank: false,
  },
  {
    text: "Good flows to me, good flows from me.",
    fillInBlank: false,
  },
  {
    text: "I speak with confidence and ",
    fillInBlank: true,
  },
  {
    text: "I can do anything.",
    fillInBlank: false,
  },
  {
    text: "I forgive myself.",
    fillInBlank: false,
  },
  {
    text: "I will always be there for myself.",
    fillInBlank: false,
  },
  {
    text: "I follow my inner guidance.",
    fillInBlank: false,
  },
  {
    text: "I find inner peace when ",
    fillInBlank: true,
  },
  {
    text: "My future is ",
    fillInBlank: true,
  },
  {
    text: "I am thankful that I get to live another day.",
    fillInBlank: false,
  },
  {
    text: "Today I focus on my ",
    fillInBlank: true,
  },
  {
    text: "I was not made to give up.",
    fillInBlank: false,
  },
  {
    text: "I am worthy of love and joy.",
    fillInBlank: false,
  },
  {
    text: "I deserve ",
    fillInBlank: true,
  },
  {
    text: "I am confident about my ",
    fillInBlank: true,
  },
  {
    text: "There is no wrong decision.",
    fillInBlank: false,
  },
  {
    text: "I feel like myself most when ",
    fillInBlank: true,
  },
  {
    text: "I am focused, persisent, and will never ",
    fillInBlank: true,
  },
  {
    text: "My life is just beginning.",
    fillInBlank: false
  },
  {
    text: "Today I intend to ",
    fillInBlank: true
  },
  {
    text: "Everyday I celebrate ",
    fillInBlank: true
  },
  {
    text: "I forgive myself and set myself free.",
    fillInBlank: false
  },
  {
    text: "I am enough.",
    fillInBlank: false
  },
  {
    text: "I am deeply fulfilled by what I do.",
    fillInBlank: false
  },
  {
    text: "I am great at what I do.",
    fillInBlank: false
  },
  {
    text: "I am courageous and stand up for myself.",
    fillInBlank: false
  },
  {
    text: "My voice matters.",
    fillInBlank: false
  },
  {
    text: " I’ll surround myself with positive people who will help bring out the best in me.",
    fillInBlank: false
  },
  {
    text: "I'm allowed to take time to heal.",
    fillInBlank: false
  },
  {
    text: "I let go of all that no longer serves me.",
    fillInBlank: false
  },
  {
    text: "Difficult times are part of my journey and allow me to appreciate the good.",
    fillInBlank: false
  },
  {
    text: "I am blessed with an amazing family and friends who love and support me.",
    fillInBlank: false
  },
  {
    text: "I’m open to new adventures in my life.",
    fillInBlank: false
  },
  {
    text: "Guilt does not change the past. Worrying does not change the future.",
    fillInBlank: false
  },
  {
    text: "I’m free to create the life I desire.",
    fillInBlank: false
  },
  {
    text: "I’m bold, beautiful, and brilliant.",
    fillInBlank: false
  },
  {
    text: "I make small steps towards my goals.",
    fillInBlank: false
  },
  {
    text: "My dream is to ",
    fillInBlank: true
  },
  {
    text: "I am not looking to escape my darkness. I am learning to love myself there.",
    fillInBlank: false
  },
  {
    text: "I allow myself to make mistakes. It is part of my beautiful growth.",
    fillInBlank: false
  },
  {
    text: "I let go of the old and make room for the new.",
    fillInBlank: false
  },
  {
    text: "Today and every day, I simply choose to love myself.",
    fillInBlank: false
  },
  {
    text: "I release self-judgment and accept self-love.",
    fillInBlank: false
  },
  {
    text: "Today I choose to let go of ",
    fillInBlank: true
  },
  {
    text: "I am loved beyond comprehension.",
    fillInBlank: false
  },
  {
    text: "I believe in the person I dream of becoming.",
    fillInBlank: false
  },
  {
    text: "I can learn anything. I can be anything.",
    fillInBlank: false
  },
  {
    text: "Looking back, I honor how far I have come.",
    fillInBlank: false
  },
  {
    text: "My brightest days are still to come.",
    fillInBlank: false
  },
  {
    text: "I listen to my body and give it what it needs.",
    fillInBlank: false
  },
  {
    text: "I trust my intuition. I listen to it. It knows just what to do.",
    fillInBlank: false
  },
  {
    text: "I positively impact those are me just by being myself.",
    fillInBlank: false
  },
  {
    text: "I love myself just as much as I love others.",
    fillInBlank: false
  },
  {
    text: "I attract amazing people just by being myself.",
    fillInBlank: false
  },
  {
    text: "There is no rainbow without the rain.",
    fillInBlank: false
  },
  {
    text: "I do what I love. I love what I do.",
    fillInBlank: false
  },
  {
    text: "I don't need anyone's approval but my own.",
    fillInBlank: false
  },
  {
    text: "With every breath I take, I inhale courage and exhale fear.",
    fillInBlank: false
  },
  {
    text: "Everything that is happening right now is truly for my highest good.",
    fillInBlank: false
  },
  {
    text: "My dream is to ",
    fillInBlank: true
  },
  {
    text: "I am so incredibly grateful for ",
    fillInBlank: true
  },
  {
    text: "I have always been enough.",
    fillInBlank: false
  },
  {
    text: "I will always be enough.",
    fillInBlank: false
  },
  {
    text: "I am worthy of my wildest dreams and I make them my reality.",
    fillInBlank: false
  },
  {
    text: "I am taking my journey one day at a time.",
    fillInBlank: false
  },
  {
    text: "Today I believe in myself.",
    fillInBlank: false
  },
  {
    text: "I can see the future I want to have and know that I'm on my way.",
    fillInBlank: false
  }
];
